import React from 'react';
import {Button, Input, message} from 'antd';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {navigate} from 'gatsby';
import URL_PATH from '../../UrlPath';
import ModalContent from '../../components/ModalContent';
import styled from 'styled-components';

function SettingsPage(props) {
  const app = React.useContext(AppContext.Context);

  const _logout = React.useCallback(() => {
    app.actions.staffLogout();
    navigate(URL_PATH.dashboardLogin);
  }, [app.actions]);

  const _openChangePwModal = React.useCallback(() => {
    AppActions.setModal(
      <ModalContent title={`變更密碼`}>
        <StaffChangePwModalContent />
      </ModalContent>,
    );
  }, []);

  if (!app.staffUser) {
    return null;
  }

  return (
    <Wrapper>
      <h2>使用者資料</h2>
      <div className="content">
        <div className="item">
          <label>使用者名稱</label>
          <div className="display">{app.staffUser.username}</div>
        </div>

        <div className="item">
          <label>email</label>
          <div className="display">{app.staffUser.email}</div>
        </div>

        <div className="item">
          <label>註冊時間</label>
          <div className="display">{app.staffUser.created}</div>
        </div>

        <div className="item">
          <label>更新時間</label>
          <div className="display">{app.staffUser.updated}</div>
        </div>

        <div className="item">
          <label>密碼</label>
          <Button type={'primary'} size={'small'} onClick={_openChangePwModal}>
            變更密碼
          </Button>
        </div>
      </div>

      <div className="bottom-actions">
        <Button onClick={_logout}>登出</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & > h2 {
    margin-bottom: 16px;
  }

  & > .content {
    & .item {
      display: flex;
      align-items: center;
      margin: 5px 0px;

      & > label {
        flex-basis: 110px;
        margin-right: 10px;
      }
    }
  }

  & > .bottom-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
  }
`;

function StaffChangePwModalContent(props) {
  const app = React.useContext(AppContext.Context);
  const [isLoading, setIsLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [errors, setErrors] = React.useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const _changePw = React.useCallback(async () => {
    let _nextErrors = {};
    if (!values.oldPassword) {
      _nextErrors.oldPassword = '必填';
    }

    if (!values.newPassword) {
      _nextErrors.newPassword = '必填';
    }

    if (!values.newPasswordConfirm) {
      _nextErrors.newPasswordConfirm = '必填';
    }

    if (values.newPassword.length < 8) {
      _nextErrors.newPassword = '密碼至少須為八碼';
    }

    if (values.newPassword !== values.newPasswordConfirm) {
      _nextErrors.newPasswordConfirm = '請再次確認新密碼與密碼確認欄位';
    }

    if (Object.keys(_nextErrors).length !== 0) {
      setErrors(_nextErrors);
      return;
    }

    setIsLoading(true);
    let _success = false;
    try {
      let resp = await app.actions.staffChangePw({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      message.success('使用者密碼已更新！');
      _success = true;
    } catch (err) {
      _success = false;
      let _errorStr = '';
      if (err.error === 'user_authenticate_error') {
        _errorStr = '舊密碼錯誤！';
      } else if (err.error === 'invalid_password') {
        _errorStr = '密碼格式無效，請再次確認新密碼格式';
      } else {
        _errorStr = '無法更新使用者密碼，請再試一次';
      }
      message.error(_errorStr);
    } finally {
      setIsLoading(false);
      _success && AppActions.setModal(null);
    }
  }, [app.actions, values]);

  const _onFieldChange = React.useCallback(
    (field) => (e) => {
      let _nextErrors = {
        [field]: '',
      };
      let _value = e.target.value;
      if (field === 'newPassword' || field === 'newPasswordConfirm') {
        let _value1 = e.target.value;
        let _value2 =
          values[
            field === 'newPassword' ? 'newPasswordConfirm' : 'newPassword'
          ];
        if (_value1 && _value2 && _value1 !== _value2) {
          _nextErrors.newPasswordConfirm = '密碼確認不符';
        }
      }

      setErrors({
        ...errors,
        ..._nextErrors,
      });
      setValues({
        ...values,
        [field]: e.target.value,
      });
    },
    [values, errors],
  );

  return (
    <ChangePwModalContentWrapper>
      {[
        {
          key: 'oldPassword',
          display: '舊密碼',
          placeholder: '請輸入目前的密碼',
        },
        {
          key: 'newPassword',
          display: '新密碼',
          placeholder: '密碼至少須為八碼',
        },
        {
          key: 'newPasswordConfirm',
          display: '新密碼確認',
          placeholder: '請再次輸入新密碼',
        },
      ].map((field, idx) => (
        <div className="item" key={idx}>
          <div className="row">
            <label>{field.display}</label>
            <Input.Password
              placeholder={field.placeholder}
              style={errors[field.key] ? {border: '1px solid red'} : {}}
              onChange={_onFieldChange(field.key)}
              value={values[field.key]}
            />
          </div>
          {errors[field.key] && (
            <div className="error">{errors[field.key]}</div>
          )}
        </div>
      ))}

      <Button
        loading={isLoading}
        type="primary"
        onClick={_changePw}
        style={{marginTop: 40}}>
        變更密碼
      </Button>
    </ChangePwModalContentWrapper>
  );
}
const ChangePwModalContentWrapper = styled.div`
  & > .item {
    margin: 10px 0px;
    & > .row {
      display: flex;
      align-items: center;
      & label {
        flex-basis: 100px;
        margin-right: 10px;
      }
    }

    & > .error {
      color: red;
      text-align: right;
      margin-top: 5px;
    }
  }
`;
export default SettingsPage;
