import React from 'react';
import styled from 'styled-components';
import {BREAK_POINTS} from '../domain/Constants';
import {ThemeCssVariables} from '../domain/Theme';

function ModalContent(props) {
  const {title, children, contentCss = '', wrapperCss = ''} = props;

  return (
    <Wrapper hasHeader={!!title} contentCss={contentCss} css={wrapperCss}>
      {title && <h3>{title}</h3>}
      <div className="content">{children}</div>
    </Wrapper>
  );
}

const HEADER_HEIGHT = 50;
const Wrapper = styled.div`
  ${ThemeCssVariables}
  background-color: #fff;
  width: calc(100vw - 60px);
  height: 100%;
  max-height: calc(100vh - 60px);
  max-width: 1062px;
  border-radius: 10px;
  position: relative;
  padding-top: ${(props) => (props.hasHeader ? HEADER_HEIGHT : 0)}px;
  overflow: auto; /* fix for safari */

  & > h3 {
    border-bottom: 1px solid var(--grey-300);
    font-size: 14px;
    color: #595757;
    text-align: center;
    font-weight: bold;
    height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  & > .content {
    padding: 10px 15px;
    height: 100%;
    overflow: auto;
    ${(props) => props.contentCss || ''}

    & > .footer {
      /* default footer style for modal content */
    }
  }

  /* antd style overwrite, cause we commonly use ant.steps in modal */
  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & .rev-steps {
      & .ant-steps-item-title {
        width: 0px;
      }
      &
        .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: transparent;
      }
      & .ant-steps-item-icon {
        margin-right: 0px;
      }
    }
  }
`;

export default ModalContent;
